import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground } from 'docz';
import { usePromise, useInput } from '../';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "usepromise"
    }}>{`usePromise`}</h1>
    <p><inlineCode parentName="p">{`usePromise`}</inlineCode>{` will run the given function and await its result. It can either run
immediatedly on mount or when calling the `}<inlineCode parentName="p">{`execute`}</inlineCode>{` function returned by the
hook.`}</p>
    <h2 {...{
      "id": "usage"
    }}>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import React from 'react';
import { usePromise } from '@fransvilhelm/hooks';

const Checkbox = () => {
  const { state, result, error, execute } = usePromise(
    () => fetch('/api').then((res) => res.json()),
    true,
    {
      onSuccess: () => console.log('Success!'),
      onError: () => console.error('Error!'),
    },
  );

  return (
    <div>
      {state === 'pending' && <p>Loading...</p>}
      {state === 'rejected' && <p>An error occured ({error.message})</p>}
      {state === 'resolved' && (
        <div>
          <img src={result.avatar_url} alt="" />
          <p>{result.login}</p>
        </div>
      )}
    </div>
  );
};
`}</code></pre>
    <h2 {...{
      "id": "example"
    }}>{`Example`}</h2>
    <Playground __position={0} __code={'() => {\n  const input = useInput(\'\')\n  const { state, result, error, execute } = usePromise(\n    () =>\n      fetch(\'https://api.github.com/users/\' + input.value).then(res => {\n        if (res.ok) {\n          return res.json()\n        }\n        throw new Error(\'Error fetching the specified user\')\n      }),\n    false,\n  )\n  return (\n    <div>\n      <form\n        onSubmit={e => {\n          e.preventDefault()\n          execute()\n        }}\n      >\n        <label>\n          <span>Username:</span>\n          <input type=\"search\" {...input} />\n        </label>\n        <button type=\"submit\">Search</button>\n      </form>\n      {state === \'idle\' && <p>Search for a GitHub user</p>}\n      {state === \'pending\' && <p>Loading...</p>}\n      {state === \'rejected\' && <p>An error occured ({error.message})</p>}\n      {state === \'resolved\' && result.login && (\n        <div>\n          <img src={result.avatar_url} alt=\"\" />\n          <p>{result.login}</p>\n        </div>\n      )}\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Playground,
      usePromise,
      useInput,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const input = useInput('');
        const {
          state,
          result,
          error,
          execute
        } = usePromise(() => fetch('https://api.github.com/users/' + input.value).then(res => {
          if (res.ok) {
            return res.json();
          }

          throw new Error('Error fetching the specified user');
        }), false);
        return <div>
        <form onSubmit={e => {
            e.preventDefault();
            execute();
          }}>
          <label>
            <span>Username:</span>
            <input type="search" {...input} />
          </label>
          <button type="submit">Search</button>
        </form>
        {state === 'idle' && <p>Search for a GitHub user</p>}
        {state === 'pending' && <p>Loading...</p>}
        {state === 'rejected' && <p>An error occured ({error.message})</p>}
        {state === 'resolved' && result.login && <div>
            <img src={result.avatar_url} alt="" />
            <p>{result.login}</p>
          </div>}
      </div>;
      }}
    </Playground>
    <h2 {...{
      "id": "parameters"
    }}>{`Parameters`}</h2>
    <p><inlineCode parentName="p">{`usePromise`}</inlineCode>{` accepts three parameters – one required and two optional.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Param`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Required`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`promise`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`() => Promise<any>`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`true`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`A function returning a promise`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`immediate`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Wether to run the promise immediatedly on mount or only when `}<inlineCode parentName="td">{`execute`}</inlineCode>{` function is called`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`options`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`object`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`An object with the optional methods `}<inlineCode parentName="td">{`onSuccess`}</inlineCode>{` and `}<inlineCode parentName="td">{`onError`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "returns"
    }}>{`Returns`}</h2>
    <p><inlineCode parentName="p">{`usePromise`}</inlineCode>{` returns an object with four properties`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Prop`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`state`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`'idle'`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="td">{`'pending'`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="td">{`'resolved'`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="td">{`'rejected'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Current state of the promise`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`result`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`null`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="td">{`any`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Promise functions result if `}<inlineCode parentName="td">{`state === 'resolved'`}</inlineCode>{`, otherwise `}<inlineCode parentName="td">{`null`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`error`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{` `}<inlineCode parentName="td">{`null`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="td">{`unknown`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Error thrown by promise function if `}<inlineCode parentName="td">{`state === 'rejected'`}</inlineCode>{`, otherwise `}<inlineCode parentName="td">{`null`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`execute`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`() => void`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Callback function to execute the given promise functions`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      